import {useEffect, useState} from 'react';

/**
 * A React hook which changes style based on the scroll position
 * @param {number} threshold What scroll position to change from initialStyl to
 * final Style
 * @param {object} initialStyle The inital style
 * @param {object} finalStyle The final style
 * @return {object} A dynamic style based on scroll position
 */
export function useScrollBasedStyle(threshold, initialStyle, finalStyle) {
  return useScrollBasedStyles({0: initialStyle, [threshold]: finalStyle});
}

/**
 * A React hook which changes style based on the scroll position (unlimited
 * breakpoints)
 * @param {*} thresholdStyle - key value pairs of scrollPosition: {object} style
 * eg: thresholdStyle:{
 *   0: {opacity:0},
 *   100: {opacity:1}
 *   x: {opacity: 0.5},
 * }
 * @return {object} A dynamic style object based on scroll position
 */
export function useScrollBasedStyles(thresholdStyle) {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Sort thresholdStyle:
  const sortedThresholdStyle = Object.keys(thresholdStyle)
      .sort()
      .reduce((acc, key) => ({
        ...acc, [key]: thresholdStyle[key],
      }), {});
  const handleScroll = () => setOffset(window.pageYOffset);

  let style = sortedThresholdStyle[Object.keys(sortedThresholdStyle)[0]];
  for (const thresh of Object.keys(sortedThresholdStyle)) {
    if (thresh > offset) {
      break;
    }

    style = sortedThresholdStyle[thresh];
  }

  return style;
}
