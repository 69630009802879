import PropTypes from 'prop-types';
import React from 'react';
import styled, {keyframes} from 'styled-components';

import {theme} from 'src/styles/theme';

import Popup from 'src/components/functional/popup';
import Spacer from 'src/components/graphical/spacer';
import {useFeatureFlags} from 'src/functionality/flags-provider';

const disappear = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
`;

const Wrapper = styled.div`
  color: black;
  text-align: center;;
`;

const Tag = styled.span`
  height: 2em;
  width: 4.5em;
  text-align: center;
  z-index: 100;
  opacity: 1;
  background: ${theme.contentTagColour};
  opacity: 1;
  position: absolute;
  
  // disappear after 2s
  animation-name: ${disappear};
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  margin-left: -2.75em;
  margin-top: 1em;
  transform: rotate(-90deg);
  
  &:hover{
    opacity: 1!important;
  }
`;

const ContentTag = (props) => {
  const flags = useFeatureFlags();
  return flags.EnableContentTags ?
    (
      <Popup trigger={
        <Tag id={String(props.children)} className="btn-sm btn-primary">
          {props.children}
        </Tag>
      }>
        <Wrapper>
          <Spacer height={5}></Spacer>
          <h3>This is a content tag (<code>{props.children}</code>)</h3>
            Each piece of content is tagged to aid in the efficient filling of
            content throughout the arbias site.
          <br/><br/>
            See the most current content guide for more details.
          <Spacer height={10}></Spacer>
        </Wrapper>
      </Popup>
    ) : (<></>);
};

ContentTag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ContentTag;
