/* eslint-disable react/prop-types */ // TODO: Change this.
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {Link, navigate} from 'gatsby';
import {theme} from 'styles/theme';
import Brand from 'src/components/functional/brand';
import {Nav, Navbar, NavDropdown, Col, Row} from 'react-bootstrap';
import ArbiasButton from 'src/components/functional/arbias-button';

const pages = [
  {
    pageTitle: 'Home',
    pageLink: '/',
  },
  {
    pageTitle: 'About Us',
    pageLink: '/about/',
    children: [
      {
        pageTitle: 'Corporate',
        pageLink: '/corporate/',
      },
      {
        pageTitle: 'Governance',
        pageLink: '/about/people/management-and-advisors/#governance',
      },
      {
        pageTitle: 'Annual Reports',
        pageLink: '/about/resources/#Annual-reports',
      },
      {
        pageTitle: 'Working At arbias',
        pageLink: '/about/people/working-at-arbias/',
      },
    ],
  },
  {
    pageTitle: 'Services',
    pageLink: '/about/what-we-do/',
  },
  {
    pageTitle: 'News & Information',
    pageLink: '/information/',
    children: [
      {
        pageTitle: 'ABI Information',
        pageLink: '/information/',
      },
      {
        pageTitle: 'News & Publications',
        pageLink: '/about/publications/',
      },
      {
        pageTitle: 'Documents and Resources',
        pageLink: '/about/resources/',
      },
    ],
  },
];

const NavWrapper = styled(Nav)`
  display: flex;
  align-items: center;
`;

const HeaderNav = styled((props) => <Navbar {...props} />)`
  height: 100%;
  /* border-bottom: 1px solid ${theme.dark}; */
`;

const NavLink = styled(Link)`
  & > div {
    display: inline-block;
  }
`;

const FooterNavLink = styled(Link)`
  color: ${theme.xlight};
  ::before {
    content: "➤";
    padding-right: 1em;
    display: inline-block;
    font-family: initial;
  }
`;

const CustomNavDropdown = styled(NavDropdown)`
  transition: all 500ms ease-in-out;
`;

const Dropdown = ({item, key}) => {
  const [isOpen, setIsOpen] = React.useState();
  return item.children?.length > 0 ? (
    <CustomNavDropdown
      title={item.pageTitle}
      id="basic-nav-dropdown"
      show={isOpen}
      onToggle={() => navigate(item.pageLink)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {item.children?.map((child, i) => (
        <NavDropdown.Item
          href={child.pageLink}
          className="nav-link nav__submenu"
          key={i}
        >
          {child.pageTitle}
        </NavDropdown.Item>
      ))}
    </CustomNavDropdown>
  ) : (
    <NavLink to={item.pageLink} key={key} className="nav-link">
      {item.pageTitle}
    </NavLink>
  );
};

const HorizontalNavigation = (props) => {
  return (
    <HeaderNav expand="md">
      <Brand size="lg" logo link className="" />
      <Navbar.Toggle aria-controls="navbar" />
      <Navbar.Collapse id="navbar" className="justify-content-end text-right">
        <NavWrapper>
          {pages.map((page, key) => (
            <Dropdown item={page} key={key} />
          ))}
        </NavWrapper>
        &nbsp;
        <ArbiasButton small={true} to="/about/#contact">
          Contact
        </ArbiasButton>
      </Navbar.Collapse>
    </HeaderNav>
  );
};

const VerticalNavigation = (props) => {
  return (
    <>
      <NavWrapper>
        {pages.map(({pageLink, pageTitle}, key) => (
          <NavLink to={pageLink} key={key} className="nav-link">
            <div>
              <span className="nav-page-title">{pageTitle}</span>
            </div>
          </NavLink>
        ))}
      </NavWrapper>
    </>
  );
};

const FooterNavigation = (props) => {
  return (
    <>
      <NavWrapper>
        <Col>
          {pages.map(({pageLink, pageTitle}, key) => (
            <Row key={key}>
              <FooterNavLink to={pageLink} className="nav-link">
                {pageTitle}
              </FooterNavLink>
            </Row>
          ))}
        </Col>
      </NavWrapper>
    </>
  );
};

const HeaderNavigation = () => (
  <HeaderNav expand="md">
    <Navbar.Toggle aria-controls="navbar" />
    <Navbar.Collapse id="navbar" className="justify-content-end text-right">
      <NavWrapper>
        {pages.map((page, key) => (
          <Dropdown item={page} key={key} />
        ))}
      </NavWrapper>
      &nbsp;
      <ArbiasButton small={true} to="/about/#contact">
        Contact
      </ArbiasButton>
    </Navbar.Collapse>
  </HeaderNav>
);

const Navigation = (props) => {
  switch (props.variant) {
    case 'horizonatal': return <HorizontalNavigation />;
    case 'vertical': return <VerticalNavigation />;
    case 'header': return <HeaderNavigation />;
    case 'footer': return <FooterNavigation />;
    default: return null;
  }
};


Navigation.propTypes = {
  variant: PropTypes.string,
};

export default Navigation;
