import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {useContent} from 'src/functionality/content-provider';
import {Col, Row} from 'react-bootstrap';

import {theme} from 'src/styles/theme';
import ContentBlock from 'src/components/functional/content-block';
import {breakpoints} from 'src/styles/breakpoints';

import Brand from 'src/components/functional/brand';
import Navigation from 'src/components/functional/navigation';
import {Storage} from 'aws-amplify';
import {H5} from 'src/components/functional/headings';
import Strip from 'src/components/functional/strip';
import ArbiasButton from 'src/components/functional/arbias-button';

const FooterWrapper = styled.footer`
  position: relative;
  height: 100vh;
  margin-top: calc(27em - 100vh - 1px)!important;
  display: flex!important;
  align-items: flex-end;
  overflow: hidden;
  pointer-events: none;
  background: none !important;
  
  @media ${breakpoints.md} {
    margin-top: 0!important;
    height: auto;
  }
`;

const FooterBar = styled.div`
  background-color: ${theme.gray};
  pointer-events: all;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FooterBody = styled.div`
  margin-right: 27.5vw;
  margin-left: 5vw;
  
  @media ${breakpoints.lg} {
    margin-right: 5vw;
  }
`;

const BgCircle1 = styled.div`
  z-index: 1000!important;
  border-radius: 100%;
  position: absolute;
  z-index: -100;
  background-color: ${theme.primary};
  width: 32.5vw;
  height: 32.5vw;
  bottom: -15vw;
  right: -5vw;
  @media ${breakpoints.lg} {
    display: none;
  }
`;

const BgCircle2 = styled.div`
  z-index: 1000!important;
  border-radius: 100%;
  position: absolute;
  z-index: -100;
  background-color: ${theme.darkBlue};
  width: 16.5vw;
  height: 16.5vw;
  bottom: 10vw;
  right: -7.5vw;
  @media ${breakpoints.lg} {
    display: none;
  }
`;

const FooterLegal = styled.div`
  margin-top: 1em;
  padding: 0.25em 2.5vw;
  background-color: ${theme.dark};
  color: ${theme.light};
`;

const FooterText = styled.div`
  @media ${breakpoints.lg} {
    margin-right: unset;
  }
`;

const FooterImg = styled.img`
  height: 3em;
  padding: 0 0.25em;
  width: auto;
`;

const Footer = () => {
  const F01 = useContent('F01');

  const [aboriginalFlag, setAboriginalFlag] = useState();
  const [HDAACertification, setHDAACertification] = useState();
  const [NDISCertification, setNDISCertification] = useState();
  const [TSIFlag, setTSIFlag] = useState();

  useEffect(() => {
    // TODO: refactor into single function which recieves image
    /**
      * Gets Aboriginal flag from AWS amplify
      */
    async function getAboriginalFlag() {
      const file = await Storage.get('Australian_Aboriginal_Flag.svg', {
        level: 'public',
      });
      setAboriginalFlag(file);
    }
    /**
      * Gets TSI flag from AWS amplify
      */
    async function getTSIFlag() {
      const file = await Storage.get('TSI_flag.jpg', {
        level: 'public',
      });
      setTSIFlag(file);
    }
    /**
      * Gets HDAA certification from AWS amplify
      */
    async function getHDAACertification() {
      const file = await Storage.get('HDAA-cert.jpg', {
        level: 'public',
      });

      setHDAACertification(file);
    }
    /**
      * Gets NDIS certification from AWS amplify
      */
    async function getNDISCertification() {
      const file = await Storage.get('I-Love-NDIS.png', {
        level: 'public',
      });
      setNDISCertification(file);
    }
    if (!aboriginalFlag) {
      getAboriginalFlag();
    }
    if (!HDAACertification) {
      getHDAACertification();
    }
    if (!NDISCertification) {
      getNDISCertification();
    }
    if (!TSIFlag) {
      getTSIFlag();
    }
  }, [aboriginalFlag, HDAACertification, NDISCertification, TSIFlag]);


  return (
    <FooterWrapper>
      <FooterBar>
        <FooterBody>
          <Brand
            link
            logo
            variant="light"
            size="lg"
          />
          <Row className="text-light">
            {/* Navigation */}
            <Col
              sm={4} lg={3}
              className="d-flex flex-column justify-content-middle"
            >
              <Navigation variant="footer" />
              <br />
            </Col>
            {/* Contact Details */}
            <Col
              sm={8} lg={6}
              className="d-flex flex-column justify-content-middle"
              style={{marginTop: '0.65em'}}
            >
              <Strip
                className='rounded'
                padding="0.5em"
                bg={theme.dark}
              >
                <H5 light={true} noUnderline={true}>Phone</H5>
                <span className='text-nowrap'>
                  <b>Victoria: </b>
                  <a className='text-light' href="tel:+61383881222">
                    +61 3 8388 1222
                  </a>
                </span>
                <br />
                <span className='text-nowrap'>
                  <b>New South Wales: </b>
                  <a className='text-light' href="tel:+61297361821">
                    +61 2 9736 1821
                  </a>
                </span>
                <br />
                <ArbiasButton
                  small={true}
                  dark={true}
                  to="/about/#contact"
                >
                  Contact
                </ArbiasButton>
              </Strip>
            </Col>
          </Row>
          <br />
          {/* Acknowledgement of Country; certifications */}
          <FooterText className="text-light">
            <ContentBlock
              header={()=><></>}
              contentWrapper={(props) => <div
                className="text-sm" {...props}/>}
              content={{'F01': F01}}
              tag='F01'
            />
            <Row>
              <Col>
                <FooterImg
                  src={aboriginalFlag}
                  alt="Aboriginal Flag"/>
                <FooterImg
                  src={TSIFlag}
                  alt="Torres Straight Islander Flag"/>
                <FooterImg
                  src={HDAACertification}
                  alt="HDAA Certification"/>
                <FooterImg
                  src={NDISCertification}
                  alt="NDIS Certification"/>
              </Col>
            </Row>
          </FooterText>
        </FooterBody>
        <FooterLegal className="diagonal-pattern">
          <span>&copy; arbias Ltd. 2021</span>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <Link to="/legal" className="link-primary text-decoration-none">
            Legal
          </Link>
        </FooterLegal>
      </FooterBar>
      <BgCircle1 />
      <BgCircle2 />
    </FooterWrapper>
  );
};

export default Footer;
