
// Gets all of the content on the home page
export const getPage = /* GraphQL */ `
  query getPage($slug: String!) {
    listPages(filter: {slug: {eq: $slug}}) {
      items {
        contentItems {
          items {
            body
            title
            contentTag
          }
        }
      }
    }
  }
`;

// Gets a content block by id
export const getContent = /* GraphQL */ `
  query getContent($id: String!) {
    listContents(filter: {contentTag: {eq: $id}}) {
      items {
        contentTag
        title
        body
      }
    }
  }
`;


// Gets all of the publications which are currently enabled
export const listPublications = /* GraphQL */ `
  query listPublications {
    listPublications(filter: {isEnabled: {eq: true}}) {
      items {
        id
        title
        body
        date
        excerpt
        isEnabled
        slug
        imageUrl
        tags {
          items {
            tag {
              tag
            }
          }
        }
      }
    }
  }
  
`;
// Gets all of the positions which are currently enabled
export const listEnabledJobPositions = /* GraphQL */ `
  query listJobPositions {
    listJobPositions(filter: {isEnabled: {eq: true}}) {
    items {
      id
      title
      positionName
      isEnabled
      excerpt
      description
      updatedAt
    }
  }
}
`;

// Gets all settings
export const listSettings = /* GraphQL */ `
  query listSettings {
    listSettings {
      items {
        key
      }
    }
  }
`;

// Gets all active training programs
export const listTrainingPrograms = /* GraphQL */ `
  query listTrainingPrograms {
    listTrainingPrograms(filter: {isEnabled: {eq: true}}) {
      items {
        body
        description
        index
        imageUrl
        title
        excerpt
        slug
      }
    }
  }
`;

// Gets all partners
export const listPartners = /* GraphQL */ `
  query listPartners {
    listPartners {
      items {
        body
        name
        imageUrl
      }
    }
  }
`;

// Gets all initiatives
export const listInitiatives = /* GraphQL */ `
  query listInitiatives {
    listInitiatives {
      items {
        body
        excerpt
        imageUrl
        index
        title
        location
      }
    }
  }
`;

// Get root Information ID
export const getRootInformation = /* GraphQL */ `
  query getRootInformation {
    listInformationTargets(filter: {index: {eq: 0}}) {
      items {
        id
      }
    }
  }
`;

// Get all Information objects
export const listInformationTargets = /* GraphQL */ `
  query listInformationTargets {
    listInformationTargets {
      items {
        id
        index
        label
        options
        slug
        Content {
          items {
            contentTag
            title
            body
          }
        }
      }
    }
  }
`;

export const listPeople = /* GraphQL */ `
  query listPeople{
    listPeople {
      items {
        content {
          contentTag
          body
          title
        }
        isEnabled
        lastName
        firstName
        title
        profileUrl
        tags {
          items {
            tag {
              tag
            }
          }
        }
      }
    }
  }
`;
