import PropTypes from 'prop-types';
import React from 'react';

const Spacer = ({className, height, xl, lg, md, sm}) => {
  className = className ? className : '';
  return (
    <div className={className} style={{height: `${height}vh`}} />
  );
};

Spacer.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  lg: PropTypes.bool,
  md: PropTypes.bool,
  sm: PropTypes.bool,
  xl: PropTypes.bool,
};

export default Spacer;
