import {Link} from 'gatsby';
import React, {useState} from 'react';
import {
  Form} from 'react-bootstrap';

import Spacer from 'src/components/graphical/spacer';
import styled from 'styled-components';

const SubmitButton = styled.button`
  width: 100%;
`;

const FeedbackForm = (props) => {
  const rangeMax = 6;
  const [submitButtonText, setSubmitButtonText] = useState('Submit');
  const [errorText, setErrorText] = useState('');

  const sendFailHelp =
    `Error: Your feedback failed to send - try refreshing the page and retrying.
    If it's still not working, submit an enquiry using our contact form.`;

  const [formInputs, setFormInputs] = useState({
    overallRating: 3,
    navigation: 3,
    improvements: '',
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    setSubmitButtonText('Giving Feedback...');

    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'route': window.location.pathname,
        'overall-rating': formInputs.overallRating,
        'navigation': formInputs.navigation,
        'improvements': formInputs.improvements,
        // DECIMAL:
        // "overall-rating": (formInputs.overallRating/rangeMax).toFixed(3),
        // "navigation": (formInputs.navigation/rangeMax).toFixed(3),
      }),
    };
    const feedbackUrl = 'https://basa104xl2.execute-api.ap-southeast-2.amazonaws.com/default/arbias-feedback';
    return new Promise(function(resolve, reject) {
      fetch(feedbackUrl, requestOptions)
          .then((response) => response.json())
          .then((json) => {
            // if Json isnt empty
            if (json.length !== 0) {
              if (json.body.ok === true) {
                resolve(json);
              } else {
                reject(Error({
                  error: 'error - server or request error: \n',
                  response: json}));
              }
            } else {
              reject(Error({
                error: 'error - empty response: \n',
                response: json}));
            }
          })
          .catch(function(err) {
            reject(err);
          });
    }).then(
        function(json) {
          setSubmitButtonText('Feedback submitted');
        },
        function(error) {
          console.error('Fetch Error:', error);
          setErrorText(sendFailHelp);
          setSubmitButtonText('Send Error');
        },
    );
  };

  return (
    <>
      <h3 className="display-4 text-start text-primary">Website Feedback</h3>
      <Form onSubmit={onSubmit} >
        <Form.Group controlId="overall-rating">
          <Form.Label className="text-start">
            How was your overall experience on the website?
          </Form.Label><br />
          <div className="d-flex justify-content-between text-muted small">
            <div>Not great</div>
            <div>Fantastic</div>
          </div>
          <Form.Control
            min="0" max={rangeMax} step="0.5"
            custom="true"
            type="range"
            style={{width: '100%'}}
            value={formInputs.overallRating}
            onChange={(e) =>
              setFormInputs({...formInputs, overallRating: e.target.value})} />
        </Form.Group>
        <Spacer height={3} />
        <Form.Group controlId="navigation-rating">
          <Form.Label className="text-start">
            How well did you find what you were looking for?
          </Form.Label><br />
          <div className="d-flex justify-content-between text-muted small">
            <div>Couldn&#39;t find anything</div>
            <div>Found everything</div>
          </div>
          <Form.Control
            min={0} max={rangeMax} step={0.5}
            custom="true"
            type="range"
            style={{width: '100%'}}
            value={formInputs.navigation}
            onChange={(e) =>
              setFormInputs({...formInputs, navigation: e.target.value})} />
        </Form.Group>
        <Spacer height={3} />
        <Form.Group controlId="improvements">
          <Form.Label className="text-start">
            Anything we could improve?
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formInputs.improvements}
            onChange={(e) =>
              setFormInputs({...formInputs, improvements: e.target.value})}
            placeholder="I think you could improve ..."/>
        </Form.Group>
        <Spacer height={2} />
        {errorText !== '' && <div className="text-danger small pb-1">
          {errorText}
        </div>}
        <Spacer height={2} />
        <SubmitButton
          className="btn btn-primary"
          type="submit"
          disabled={submitButtonText !== 'Submit' ? true : undefined}
        >
          {submitButtonText}
        </SubmitButton>
        <Form.Text className="text-muted">
          We annonomise all data, and do not pass it on to third parties. <br />
          <Link to="/legal">See our Privacy Policy</Link>
        </Form.Text>
      </Form>
    </>
  );
};

export default FeedbackForm;
