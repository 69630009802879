import React from 'react';
import {Breadcrumb} from 'react-bootstrap';
import {useLocation} from '@reach/router';

import styled from 'styled-components';
import ShareButtons from 'src/components/functional/share';
import {breakpoints} from 'src/styles/breakpoints';

const Slug = styled.span`
  text-transform: capitalize;
  font-family: 'Sunflower', monospace;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${breakpoints.sm} {
    text-align: center;
    flex-direction: column;
  }
`;

const BreadcrumbWrapper = styled(Breadcrumb)`
  & ol{
    margin: 0;
  }
  & a{
    text-decoration: none;
  }
  @media ${breakpoints.sm} {
    margin: auto;
  }
`;

const DynamicBreadcrumb = (props) => {
  let paths = useLocation().pathname.split('/').slice(1);
  paths = ['home', ...paths];
  if (paths[paths.length - 1] == '') paths.pop();
  return (
    <Wrapper>
      <BreadcrumbWrapper>{
        paths.map((path, key) => {
          const pathText = path.toLowerCase();
          return (
            <Breadcrumb.Item
              key={key}
              // add active if it is the last breadcrumb
              active={key === paths.length-1 ? 'true' : undefined}
              // the link: '/paths[0]/paths[1]/'
              href={
                '/' + paths.slice(1, key+1).join('/') + (key !== 0 ? '/' : '')
              }
            >
              <Slug>
                {pathText}
              </Slug>
            </Breadcrumb.Item>
          );
        })}
      </BreadcrumbWrapper>
      <ShareButtons />
    </Wrapper>
  );
};

export default DynamicBreadcrumb;

