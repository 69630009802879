import {useState, useEffect} from 'react';

const flags = {
  'WhatWeDo_EnableResearch': true,
  'Home_UseWhatWeDoCategoriesNotServices': true,
  'Home_EnableHistoryAccordion': true,
  'AboutPeopleClients_EnableClientStories': false,
  'AboutPeopleClients_EnableCEOQuote': false,
  'AboutPeopleWorkingAtArbias_EnableJobAds': false,
  'Home_EnableClientStories': false,
  'EnableInformationExplorer': false,
  'Information_RelatedCategories': false,
  'OurPhilosophy_ClientCentredSupport': false,
  'Corporate_EnableHeroProfile': false,
  'Corporate_EnablePartnersSection': false,
  'WhatWeDo_EnableLookingForward': false,
  'WhatWeDo_EnableOurHealthOurWay': false,
  'WhatWeDo_EnableEducation': false,
  'Information_UseOldInfoSchema': false,
  'isTesting': false,
  'ManagersAndAdvisors_EnableProfiles': false,
  'showCovidBanner': false,
  'EnableContactPane': false,
  'showAlphaPopup': false,
  'showFeedback': false,
  'raiseMissingContentError': false,
};

/**
 * Returns the content for a given page
 * @return {array} an array of objects in format{"flagName": true/false}
 */
export function useFeatureFlags() {
  const [flagsState, setFlagsState] = useState(flags);
  useEffect(() => {
    // TODO: use an external flags provider for more nimble feature switching
    flags.EnableContentTags =
      process.env.NODE_ENV === 'development' || flags.isTesting;
    setFlagsState(flags);
  }, []);

  return flagsState;
}
