import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';

const Background = styled.div`
  background-color: ${(props) => props.bg};
  padding: ${(props) => props.padding} 0;
  width: 100%;
`;

const Strip = ({children, bg, padding, style, className}) => {
  return (
    <Background bg={bg} padding={padding} className={className}>
      <Container style={style}>
        {children}
      </Container>
    </Background>
  );
};


Strip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bg: PropTypes.string,
  padding: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Strip;
