import PropTypes from 'prop-types';
import React from 'react';
import {theme} from 'src/styles/theme';
import styled from 'styled-components';

import LogoFull from 'images/logo.svg';
import LogoFullLight from 'images/logo-light.svg';
import LogoIcon from 'images/logo-no-text.svg';


// TODO: Convert variant to dark

const BrandWrapper = styled((props) =>
    props.link ? <div {...props} /> : <a {...props}/>)`
  font-family: 'arial' sans-serif;
  font-size: ${(props) => props.size}pt;
  line-height: ${(props) => props.size}pt;
  text-decoration: none;
  color: ${(props) => props.variant === 'dark' ? theme.dark : theme.primary};
  display: flex;
  align-items: center;
`;

const BrandSpan = styled.span`
  transition: color 0.25s ease-in-out;
  color: ${(props) => props.variant === 'dark' ? theme.primary : ''};
  margin: 0.2em 0;
  padding-bottom: 0.2em; 
  
  img{
    height: 1em;
  }
`;

const Logo = styled.img`
  margin-right: 0.5em;
  height: 1em;
  width: 1em;
`;

const Brand = (props) => {
  const sizes = {
    xs: 22,
    sm: 24,
    md: 28,
    lg: 48,
    xl: 64,
  };

  const size = sizes[(typeof props.sizes === 'undefined') ? 'md' : props.sizes];

  return (
    <BrandWrapper
      size={size}
      variant={props.variant}
      href={props.link ? '/' : undefined}
      className={props.className}
    >
      {props.logo && <Logo src={LogoIcon} alt="arbias logo"/>}
      <BrandSpan variant={props.variant}>
        {props.variant === 'light' ?
        (
          <img src={LogoFullLight} alt="arbias logo" />
        ) : (
          <img src={LogoFull} alt="arbias logo" />
        )}
      </BrandSpan>
    </BrandWrapper>
  );
};

Brand.propTypes = {
  className: PropTypes.string,
  link: PropTypes.bool,
  logo: PropTypes.bool,
  sizes: PropTypes.symbol,
  variant: PropTypes.string,
};

export default Brand;
