import React from 'react';
import styled from 'styled-components';

import Pane from 'src/components/functional/pane';
import FeedbackForm from 'src/components/functional/feedback-form';

const FeedbackButton = styled.button`
  z-index: 100000;
  position: fixed;
  right: 7em;
  bottom: 0;
`;

const Feedback = (props) => {
  return (
    <div className="feedback-pane">
      <Pane trigger={
        <FeedbackButton
          className="btn btn-sm btn-primary"
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='click'
          data-amplify-analytics-attrs='type:feedback'
        >
          Website Feedback
        </FeedbackButton>
      }>
        <FeedbackForm />
      </Pane>
    </div>
  );
};

export default Feedback;


