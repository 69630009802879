export const theme = {
  bodyBg: '#F2F2F2',
  primary: '#00AEEF',
  darkPrimary: '#1E69B3',
  lightPrimary: '#bde4f2',
  secondary: '#E7EF00',
  // secondary: "#D6C634",
  // secondary: "#E1E134",
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#f8f9fa',
  xlight: '#f5f5f5',
  dark: '#363636',
  muted: '#888888',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  white: '#fff',
  lightGray: '#F9FaFc',
  mediumGray: '#DADADA',
  mediumDarkGray: '#BDBDBD',
  gray: '#535353',
  darkGray: '#363636',
  xdarkGray: '#424242',
  lightBlue: '#39C4F7',
  darkBlue: '#1E69B3',
  blue: '#229ADD',
  mbs: '#325DC2',
};
