import React from 'react';
import styled, {keyframes} from 'styled-components';

import {theme} from 'src/styles/theme';

const anamationTime = '2s';

const fadeOut = keyframes`
  from{
    opacity: 1;
  }
  to{
    opacity: 0; 
    display: none;
    z-index: -100000;
  }
`;

const Overlay = styled.div`
  z-index: 100000;
  opacity: 0;
  background-color: ${theme.bodyBg};
  animation: ${fadeOut} ${anamationTime} 0s ease forwards;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LoadingOverlay = () => {
  // TODO: only do this on the first site view
  // const showOverlay = (process.env.GATSBY_ENV !== 'development');
  const showOverlay = false;

  return (
    showOverlay && (
      <Overlay />
    )
  );
};

export default LoadingOverlay;
