import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {getPage, getContent} from 'src/graphql/content';
import {useFeatureFlags} from './flags-provider';

/**
 * Returns the content for a given page
 * @param {string} slug the page slug
 * @param {object} existingContent the existing content which to set
 * @return {object} an object with multiple contentTag:{body, title}.
 */
export function usePageContent(slug, existingContent) {
  const [content, setContent] = useState();

  useEffect(() => {
    if (!content) {
      if (existingContent) { // If the content has already been populated:
        setContent(existingContent);
      } else { // Otherwise, we fetch it from the api
        (async () => {
          const data = await API.graphql(
              {
                query: getPage,
                variables: {
                  slug: `${slug.toString().endsWith('/') ? slug : slug + '/'}`,
                },
              },
          );
          if (!data.data.listPages.items[0]) {
            // eslint-disable-next-line max-len
            console.error(`No Content retrieved for slug ${slug}. Did you try fetching url with trailing slash?`);
          }

          const content =
            data.data.listPages.items.pop().contentItems.items.reduce(
                (obj, item) => Object.assign(obj,
                    {
                      [item.contentTag]: {
                        body: item.body,
                        title: item.title,
                        contentTag: item.contentTag,
                      },
                    }),
                {});
          setContent(content);
        })();
      }
    }
  }, [content, existingContent, slug]);

  return (content);
}

/**
 * Returns a given content element
 * @param {string} id the contentTag ID
 * @return {object} the contentTag
 */
export function useContent(id) {
  const [content, setContent] = useState();
  const flags = useFeatureFlags();

  useEffect(() => {
    if (!content) {
      API.graphql({
        query: getContent,
        variables: {id: `${id}`},
      })
          .catch((e) => console.error(e))
          .then((data) => {
            if (data.data.listContents.items.length === 0) {
              if (flags.raiseMissingContentError) {
                Promise.reject(
                    new Error(`No results for fetch of content block ${id}`),
                );
              }
            };
            return data.data.listContents.items;
          })
          .then((items) =>
            items.reduce(
                (obj, item) => Object.assign(obj,
                    {
                      [item.contentTag]: {
                        body: item.body,
                        title: item.title,
                        contentTag: item.contentTag,
                      },
                    }),
                {})[id])
          .then((content) => setContent(content))
          .catch((e) => console.error(e));
    }
  }, [content, id]);

  return (content);
}
