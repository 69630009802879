import React from 'react';
import styled from 'styled-components';
import {useLocation} from '@reach/router';

const Icon = styled.i`
  margin: 0 0.25em;
  padding: 0.25em;
  width: 1.5em;
  text-align: center;
  transition: all 0.05s ease-in-out;
  border-radius: 2px;
  & :hover{
    filter: brightness(0.9);
  }
`;

const Facebook = styled(Icon)`
  background-color: #4267B2;
  color: white;
`;
const Twitter = styled(Icon)`
  background-color: #1DA1F2;
  color: white;
`;
const CopyLink = styled(Icon)`
  background-color: #1f3c4a;
  color: white;
`;

const Button = styled.button`
  background-color: unset;
  border: none;
`;

const ShareButtons = () => {
  const location = useLocation();
  const url = location.href;

  return (
    <div>
      <span className="text-muted">Share this page:&nbsp;</span>
      <a href={`https://facebook.com/sharer/share.php?u=${url}`} target="_blank" rel="noreferrer">
        <Facebook className="fa fa-facebook shadow-sm" />
      </a>
      <a href={`https://www.twitter.com/intent/tweet?url=${url}&hashtags=arbias`} target="_blank" rel="noreferrer">
        <Twitter className="fa fa-twitter shadow-sm" />
      </a>
      <Button onClick={() => {
        // Source from https://www.w3schools.com/howto/howto_js_copy_clipboard.asp

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(url);

        /* Alert the copied text */
        alert('Copied page link to clipboard!');
      }}>
        <CopyLink className="fa fa-copy shadow-sm" />
      </Button>
    </div>
  );
};

export default ShareButtons;
