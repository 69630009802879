import PropTypes from 'prop-types';
import React from 'react';
import Markdown from 'react-markdown';
import ContentTag from 'src/components/functional/content-tag';
import styled from 'styled-components';


const DivContentWrapper = styled.div`
    ul,ol{
      list-style-type: unset;
    }
    b{
      font-weight: bold;
    }
  `;
const ContentBlock = (props) => {
  const ContentWrapper = props.contentWrapper ?
    props.contentWrapper : DivContentWrapper;

  const content = props.content && props.content[props.tag];

  return (
    <div className={props.className}>
      <props.header>{content?.title}</props.header>
      <ContentTag>{props.tag}</ContentTag>
      <ContentWrapper>
        {content?.body.split("\\n").map((body, key) => (
          <Markdown key={key}>{body}</Markdown>
        ))}
      </ContentWrapper>
      {props.linkText && props.link && (
        <props.link>{props.linkText}</props.link>
      )}
    </div>
  );
};

ContentBlock.propTypes = {
  contentWrapper: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  content: PropTypes.objectOf(
      PropTypes.shape({
        body: PropTypes.string,
        contentTag: PropTypes.string,
        title: PropTypes.string,
      }),
  ),
  tag: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  linkText: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  className: PropTypes.string,
};


export default ContentBlock;
