import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import Amplify from 'aws-amplify';
import {Analytics, Auth} from 'aws-amplify';
import awsconfig from 'src/aws-exports';

import styled from 'styled-components';

import {theme} from 'src/styles/theme';

import {Container} from 'react-bootstrap';
import Header from 'src/components/functional/header';
import Footer from 'src/components/functional/footer';
import AlphaPopup from 'src/components/functional/alpha-popup';
import CovidBanner from 'src/components/functional/covid-banner';
import DynamicBreadcrumb from 'src/components/functional/dynamic-breadcrumb';
import Feedback from 'src/components/functional/feedback';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import LoadingOverlay from 'src/components/graphical/loading-overlay';

import 'font-awesome/css/font-awesome.min.css';
import 'styles/global.scss';

// TODO: move this so that it isn't executed on every page, just on entry point

// Amplify Configuration
const awsExportsOverride = {
  ...awsconfig,
  aws_appsync_authenticationType: 'AWS_IAM',
};
Amplify.configure(awsExportsOverride);


const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: process.env.GATSBY_PINPOINT_APP_ID,
    // Amazon service region
    region: 'ap-southeast-2',
    mandatorySignIn: false,
  },
};

Auth.currentCredentials()
    .then((d) => console.log('Amplify Credentials: ', d))
    .catch((e) => console.error(e));

// Log analytics only if we're not in development
if (process.env.GATSBY_ENV === 'development') {
  Analytics.configure({disabled: true});
} else {
  Analytics.configure(analyticsConfig);
}

Analytics.autoTrack('pageView', {
  enable: true,
  attributes: { },
  // OPTIONAL, to get the current page url
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
});

Analytics.autoTrack('event', {
  enable: true,
  events: ['click'],
  selectorPrefix: 'data-amplify-analytics-',
});

const Wrapper = styled.div`
  background-color: ${(props) => props.white ? theme.white : 'unset'};
  z-index: -1000000000;
`;

const Page = (props) => {
  useEffect(() => {
    Analytics.record({name: 'page-visit'});
  }, []);

  const flags = useFeatureFlags();

  return (
    <Wrapper white={props.white}>
      <LoadingOverlay />
      <CovidBanner />
      {flags.showAlphaPopup && <AlphaPopup /> }
      {flags.showFeedback && <Feedback /> }
      {!props.noHeader &&
        <Header home={!props.home}></Header>
      }
      <div
        className="page-wrapper"
        style={props.home ? undefined :
          props.noHeader ? undefined : {marginTop: 'calc(4rem)'}}
      >
        {props.breadcrumbs && // Add breadcrumb if the breadcrumb flag is set
          <Container className="root-container">
            <DynamicBreadcrumb />
          </Container>
        }
        {props.children}
      </div>
      {!props.noFooter &&
        <Footer />
      }
    </Wrapper>
  );
};

Page.propTypes = {
  breadcrumbs: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  home: PropTypes.bool,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  white: PropTypes.bool,
};

export default Page;
