import React from 'react';
import styled from 'styled-components';

import {theme} from 'src/styles/theme';
import Brand from 'src/components/functional/brand';

import Popup from './popup';

import logo from 'src/images/mbs-logo.png';

const LearnMoreButton = styled.a`
  z-index: 100000;
  position: fixed;
  right: 0;
  bottom: 0;
  text-decoration: none;
`;

const MbsButton = styled.a`
  margin: auto;
  color: white;
  background-color: ${theme.mbs};
  &:hover{
    background-color: ${theme.dark};
    color: ${theme.light};
  }
`;

const MbsLogo = styled.img`
  width: 25em;
`;

const AlphaPopup = () => {
  return (
    <>
      <Popup hide trigger={
        <LearnMoreButton size="sm" className="btn btn-sm btn-dark">
          Learn More
        </LearnMoreButton>
      }>
        <div>
          <Brand />
          <br/>
          <h2>Development preview</h2>
          <br/>
          <p>
          This page is publicly available to anybody with the login details.
            <br/>
          This page will be updated automatically with any major changes made.
          </p>
          <br/>

          <hr/>

          <MbsLogo
            alt="Mark Blashki Solutions"
            src={logo}
          />
          <br/>
          <br/>
          <MbsButton
            href="http://markblashki.com/"
            className="btn"
          >
          Learn more about Mark Blashki Solutions
          </MbsButton>
          <hr/>
        </div>
      </Popup>
    </>
  );
};


export default AlphaPopup;
