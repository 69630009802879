import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

import {theme} from 'src/styles/theme';

const Button = styled((props) => props.to ?
    <Link {...props} />:
    <span {...props} />)`
  display: ${(props) => props.right ? 'block' : 'inline-block'};
  padding: ${(props) => props.small ? '0.35em 1.25em' : '0.7em 2.5em'};
  border: 3px solid ${theme.primary};
  border-radius: 50vh 50vh 50vh 0;
  transition: all 0.4s ease;
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  font-weight: 500;
  cursor: pointer;
  float: ${(props) => props.right ? 'right' : 'unset'};
  border-radius: ${(props) =>
    props.right ? '50vh 50vh 0 50vh' : '50vh 50vh 50vh 0'};
  background-color: transparent;
  text-decoration: none;
  
  transition: all 0.4s ease;
  color: ${(props) => props.dark ? theme.light : theme.dark};
  
  &:active {
    background-color: ${theme.blue}!important;
    border: 3px solid ${theme.blue};
  }
  
  &:hover {
    color: ${theme.light};
    background-color: ${theme.primary};
  }
`;

const ArbiasButton = (props) => {
  return (
    <Button
      right={props.right ? 'true' : undefined}
      dark={props.dark ? 'true' : undefined}
      onClick={props.onClick}
      to={props.href ? props.href : props.to}
      className="plain-button arbias-button"
      small={props.small}
    >
      {props.children}
    </Button>
  );
};

ArbiasButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  href: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  dark: PropTypes.bool,
  right: PropTypes.bool,
  small: PropTypes.bool,
};

export default ArbiasButton;
