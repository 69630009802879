import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {theme} from 'src/styles/theme';
import {breakpoints} from 'src/styles/breakpoints';

import {useScrollBasedStyles} from 'src/functionality/scroll-based-style';
import Brand from 'src/components/functional/brand';
import Navigation from 'src/components/functional/navigation';

const HeaderWrapper = styled.header`
  width: 100%;
  height: 4rem;
  padding: 0 1em;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.variant === 'dark' ? theme.dark : theme.light}!important;
  transition: top 0.5s ease-in-out;
  
  @media ${breakpoints.lg}{
    top: 0!important;
  }
  @media ${breakpoints.md}{
    height: auto;
  }
`;

const Header = (props) => {
  const scrollVisibility = useScrollBasedStyles({
    0: {top: props.home ? 0 : '-100%'},
    600: {top: 0},
  });

  const variant = 'light';

  return (
    <HeaderWrapper
      style={{...scrollVisibility}}
      className="shadow"
      variant={variant}
    >
      <Brand
        // swap dark and light variants
        variant={variant === 'light' ? 'dark' : 'light}'}
        size="md"
        logo
        link
      />
      <Navigation variant='header'/>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  home: PropTypes.bool,
};

export default Header;
