import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Button, Container} from 'react-bootstrap';
import styled from 'styled-components';

const Window = styled.div`
  z-index: 100000;
  margin: auto;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2%;
  position: fixed;
`;

const Overlay = styled.div`
  z-index: 100000;
`;

const Popup = (props) => {
  const [showPopup, setShowPopup] = useState(props.show);

  const show = () => setShowPopup(true);
  const hide = () => setShowPopup(false);
  return (
    <Overlay>
      <div role="button" tabIndex={0} onClick={show} onKeyDown={show}>
        {/* TODO: this may not be the best way of doing this. */}
        {props.trigger}
      </div>
      {showPopup &&
          <Window className="bg-gray-light rounded shadow">
            <div className="d-flex flex-row-reverse">
              <Button variant="danger" onClick={hide}>X</Button>
            </div>
            <Container>
              {props.children}
            </Container>
          </Window>
      }
    </Overlay>
  );
};

Popup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  show: PropTypes.bool,
  trigger: PropTypes.element,
};


export default Popup;
