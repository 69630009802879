import React, {useState, useEffect} from 'react';
import ContentBlock from 'src/components/functional/content-block';
import ArbiasButton from 'src/components/functional/arbias-button';
import {H3} from 'src/components/functional/headings';
import {theme} from 'src/styles/theme';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import {useContent} from 'src/functionality/content-provider';
import {Container} from 'react-bootstrap';
import {useLocation} from '@reach/router';
import styled from 'styled-components';

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1.5em;
  background-color: ${() => theme.bodyBg};
  z-index: 1000;
  box-shadow: 0px 0px 18px 5px rgba(0,0,0,0.25);
  opacity: ${(props) => props.visible ? 1 : 0};
  pointer-events: ${(props) => props.visible ? 'unset' : 'none'};
  transition: all 0.5s;
`;

const Wrapper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  & button{
    transition: all 0.15s;
    width: 1em;
    margin-left: calc(100% - 1em);
  }
  & button:hover{
    color: ${() => theme.primary};
  }
`;

const CovidBanner = () => {
  const COV01 = useContent('COV01');
  const [visible, setVisible] = useState();
  const [scrollY, setScrollY] = useState(0);
  const flags = useFeatureFlags();
  const location = useLocation();

  useEffect(() => {
    localStorage['hasAcknowledgedCovidBanner'] = !flags.isTesting;
  }, [flags.isTesting]);

  const updateScroll = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener('scroll', updateScroll);
    };
    watchScroll();
    return () => {
      window.removeEventListener('scroll', updateScroll);
    };
  });

  useEffect(() => {
    const shouldDisplay = () => {
      const buffer = window.innerHeight/2;
      const isAtPageBottomScroll = Math.ceil(window.innerHeight + scrollY) >=
        document.documentElement.scrollHeight - buffer;

      // const isPastFirstScreenScroll = scrollY > window.innerHeight*0.75;
      const hasAcknowledgedBanner =
        localStorage['hasAcknowledgedCovidBanner'] === 'true';

      return flags.showCovidBanner &&
        !hasAcknowledgedBanner &&
        !isAtPageBottomScroll &&
        location.pathname !== '/about/health-and-safety/';
    };

    setVisible(shouldDisplay());
  }, [visible, scrollY, location.pathname, flags.showCovidBanner]);


  return (
    <Banner visible={visible}>
      <Wrapper>
        <button className="plain-button" onClick={() => {
          setVisible(false);
          localStorage['hasAcknowledgedCovidBanner'] = true;
        }}>
          <i className="fa fa-close" />
        </button>
        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={{'COV01': COV01}}
          tag='COV01'
          linkText='More on COVID-safety >'
          link={
            (props) =>
              <ArbiasButton to="/about/health-and-safety/#COVID" {...props}/>
          }
        />
      </Wrapper>
    </Banner>
  );
};

export default CovidBanner;
