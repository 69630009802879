import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from 'src/styles/theme';
import {breakpoints} from 'src/styles/breakpoints';

const HeadingSizes = {
  'h1': 2.5,
  'h2': 2,
  'h3': 1.75,
  'h4': 1.5,
  'h5': 1.25,
  'h6': 1,
};

const DisplayHeadingSizes = {
  'h1': 6,
  'h2': 5.5,
  'h3': 4.5,
  'h4': 3.5,
  'h5': 2.5,
  'h6': 1.5,
};

const Heading = styled.span`
  text-transform: ${(props) => props.display ? 'unset' : 'uppercase'};
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: ${(props) => {
    return (props.display ?
      DisplayHeadingSizes['h'+props.display] : HeadingSizes[props.as]) + 'em';
  }}!important;
  color: ${(props) => props.light ? theme.light : theme.dark};
  width: 100%;
  
  @media ${breakpoints.md} {
    font-size: ${(props) => 0.75*(props.display ?
        DisplayHeadingSizes['h'+props.display] : HeadingSizes[props.as]) + 'em'}
        !important;
  }
  @media ${breakpoints.sm} {
    font-size: ${(props) => 0.75*(props.display ?
        0.75*DisplayHeadingSizes['h'+props.display] :
        HeadingSizes[props.as]) + 'em'}
        !important;
  }
`;

const Line = styled.span`
  margin-top: 1rem;
  content: "";
  display: ${(props) => props.noUnderline ? 'none' : 'inline-block'};
  height: 0.175rem;
  width: 16rem;
  max-width: 70vw;
  background-color: ${theme.secondary};
`;

const H1 = ({children, style, className, noUnderline, display, light}) => (
  <>
    <Heading
      style={style} as="h1" className={className}
      display={display} light={light}>
      {children}
    </Heading>
    <Line noUnderline={noUnderline}/>
  </>
);
const H2 = ({children, style, className, noUnderline, display, light}) => (
  <>
    <Heading
      style={style} as="h2" className={className}
      display={display} light={light}>
      {children}
    </Heading>
    <Line noUnderline={noUnderline}/>
  </>
);
const H3 = ({children, style, className, noUnderline, display, light}) => (
  <>
    <Heading
      style={style} as="h3" className={className}
      display={display} light={light}>
      {children}
    </Heading>
    <Line noUnderline={noUnderline}/>
  </>
);
const H4 = ({children, style, className, noUnderline, display, light}) => (
  <>
    <Heading
      style={style} as="h4" className={className}
      display={display} light={light}>
      {children}
    </Heading>
    <Line noUnderline={noUnderline}/>
  </>
);
const H5 = ({children, style, className, noUnderline, display, light}) => (
  <>
    <Heading
      style={style} as="h5" className={className}
      display={display} light={light}>
      {children}
    </Heading>
    <Line noUnderline={noUnderline}/>
  </>
);
const H6 = ({children, style, className, noUnderline, display, light}) => (
  <>
    <Heading
      style={style} as="h6" className={className}
      display={display} light={light}>
      {children}
    </Heading>
    <Line noUnderline={noUnderline}/>
  </>
);

H1.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.object,
  className: PropTypes.string,
  noUnderline: PropTypes.bool,
  display: PropTypes.number,
  light: PropTypes.bool,
};

H2.propTypes = H1.propTypes;
H3.propTypes = H1.propTypes;
H4.propTypes = H1.propTypes;
H5.propTypes = H1.propTypes;
H6.propTypes = H1.propTypes;

export {H1, H2, H3, H4, H5, H6};
