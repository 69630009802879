import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';

import {theme} from 'src/styles/theme';

const PaneWrapper = styled.div`
  position: fixed;
  z-index: 150000;
  right: 0;
  top: 0;
  bottom: 0;
  width: 33.33vw;
  min-width: 375px;
  display: flex;
  align-items: center;
  padding: 0 0.5%;
  transition: all 0.4s ease;
  transform: ${(props) => props.hidden ? 'unset' : '100%'};
  
  background-color: ${(props) =>
    props.dark ? theme.darkGray : theme.light}!important;
  color: ${(props) => props.dark ? theme.light : theme.dark}!important;
`;

const Inner = styled.div`
  height: auto;
  overflow-y: auto;
  max-height: 100vh;
  width: 100%;
  
  padding: 5% 2%;
`;

const Exit = styled.button`
  position: fixed;
  top: 1em;
  right: 1em;
`;

const Pane = (props) => {
  const [showPane, setShowPane] = useState(false);
  const show = () => setShowPane(true);
  const hide = () => setShowPane(false);

  return (
    <>
      {/* TODO: this may not be the best way of doing this. */}
      <div role="button" tabIndex={0} onClick={show} onKeyDown={show}>
        {props.trigger}
      </div>
      <PaneWrapper
        dark={props.dark && true}
        hidden={!showPane}
        className="shadow-lg"
      >
        <Exit className="plain-button" onClick={hide}>&times;</Exit>
        <Inner className={`pane ${props.dark ? 'dark' : ''}`}>
          {props.children}
        </Inner>
      </PaneWrapper>
    </>
  );
};

Pane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dark: PropTypes.bool,
  trigger: PropTypes.element.isRequired,
};

export default Pane;

